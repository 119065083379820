import { default as certificateskgDtILOXgMeta } from "F:/web/pages/account/certificate.vue?macro=true";
import { default as collectHb0X7YGbnpMeta } from "F:/web/pages/account/collect.vue?macro=true";
import { default as coupongFs2enfvyZMeta } from "F:/web/pages/account/coupon.vue?macro=true";
import { default as courses6sTMZVyUHMeta } from "F:/web/pages/account/course.vue?macro=true";
import { default as examV0rtlt1O9pMeta } from "F:/web/pages/account/exam.vue?macro=true";
import { default as generalexamGWcJxAPLbsMeta } from "F:/web/pages/account/generalexam.vue?macro=true";
import { default as gradee6LZ5GvgDkMeta } from "F:/web/pages/account/grade.vue?macro=true";
import { default as homeworklybOEgbQZHMeta } from "F:/web/pages/account/homework.vue?macro=true";
import { default as infoZ07sIWFDc9Meta } from "F:/web/pages/account/info.vue?macro=true";
import { default as materialXWpVoRx6ZpMeta } from "F:/web/pages/account/material.vue?macro=true";
import { default as indexD5QxabqxJEMeta } from "F:/web/pages/account/message/index.vue?macro=true";
import { default as notekQt1IMV9ocMeta } from "F:/web/pages/account/note.vue?macro=true";
import { default as indexoEfduergfDMeta } from "F:/web/pages/account/order/index.vue?macro=true";
import { default as papergiA0TyRKu5Meta } from "F:/web/pages/account/paper.vue?macro=true";
import { default as scoreQvpcA8od9nMeta } from "F:/web/pages/account/score.vue?macro=true";
import { default as thesisRKwWO18mmSMeta } from "F:/web/pages/account/thesis.vue?macro=true";
import { default as aiSayOJYAxSrzXIMeta } from "F:/web/pages/ai/aiSay.vue?macro=true";
import { default as aiTalkDZcCNWWCivMeta } from "F:/web/pages/ai/aiTalk.vue?macro=true";
import { default as indexUFYC8pCTFxMeta } from "F:/web/pages/apply/index.vue?macro=true";
import { default as listHsDCDCSIReMeta } from "F:/web/pages/article/list.vue?macro=true";
import { default as viewJRbPsr31SWMeta } from "F:/web/pages/article/view.vue?macro=true";
import { default as SignNameX3DsLRdTwTMeta } from "F:/web/pages/contract/SignName.vue?macro=true";
import { default as indexI8q0cwWpsEMeta } from "F:/web/pages/contract/index.vue?macro=true";
import { default as signaturelfnJsbs6fdMeta } from "F:/web/pages/contract/signature.vue?macro=true";
import { default as audioyPZBy7RrbQMeta } from "F:/web/pages/course/audio.vue?macro=true";
import { default as combinezhCWY5LNjXMeta } from "F:/web/pages/course/combine.vue?macro=true";
import { default as contractDialogMcowl6GlCdMeta } from "F:/web/pages/course/contractDialog.vue?macro=true";
import { default as graphicaaC479eP0gMeta } from "F:/web/pages/course/graphic.vue?macro=true";
import { default as listpCQo1P09rHMeta } from "F:/web/pages/course/list.vue?macro=true";
import { default as liveEpvQ5aWZ5vMeta } from "F:/web/pages/course/live.vue?macro=true";
import { default as singlewuwnl0uihnMeta } from "F:/web/pages/course/single.vue?macro=true";
import { default as subjectwfiIA1Lok7Meta } from "F:/web/pages/course/subject.vue?macro=true";
import { default as topicSb95cltA58Meta } from "F:/web/pages/course/topic.vue?macro=true";
import { default as videoCkFMfWQ5dAMeta } from "F:/web/pages/course/video.vue?macro=true";
import { default as EntrancetestHeadO4DvfzGvvNMeta } from "F:/web/pages/entrancetest/EntrancetestHead.vue?macro=true";
import { default as SubmitSuccessncQAi7zIpJMeta } from "F:/web/pages/entrancetest/SubmitSuccess.vue?macro=true";
import { default as TestItemuGfvRalQNHMeta } from "F:/web/pages/entrancetest/TestItem.vue?macro=true";
import { default as exam6t9viuB86JMeta } from "F:/web/pages/entrancetest/exam.vue?macro=true";
import { default as indexXRiX2TnSURMeta } from "F:/web/pages/entrancetest/index.vue?macro=true";
import { default as reportsfBAYIFGxAMeta } from "F:/web/pages/entrancetest/report.vue?macro=true";
import { default as analysise8AywoaF5gMeta } from "F:/web/pages/exam/analysis.vue?macro=true";
import { default as emigratedyi68sMeQqBMeta } from "F:/web/pages/exam/emigrated.vue?macro=true";
import { default as indexRcYyPkolKhMeta } from "F:/web/pages/exam/index.vue?macro=true";
import { default as listY9sNhLgdQDMeta } from "F:/web/pages/exam/list.vue?macro=true";
import { default as resultsze2OKmNPiMeta } from "F:/web/pages/exam/result.vue?macro=true";
import { default as startExammabOEQs39MMeta } from "F:/web/pages/exam/startExam.vue?macro=true";
import { default as homeworkZN0W8YuXPmMeta } from "F:/web/pages/grade/homework.vue?macro=true";
import { default as listJbDygZMHUDMeta } from "F:/web/pages/grade/list.vue?macro=true";
import { default as material0OtTWMpsAeMeta } from "F:/web/pages/grade/material.vue?macro=true";
import { default as practiceWK4JnLO22dMeta } from "F:/web/pages/grade/practice.vue?macro=true";
import { default as viewppsA2hot8sMeta } from "F:/web/pages/grade/view.vue?macro=true";
import { default as indexatGbTRBqxAMeta } from "F:/web/pages/guide/index.vue?macro=true";
import { default as indexJC8TYTmsskMeta } from "F:/web/pages/index.vue?macro=true";
import { default as index_hnkj9U2RmNVXVyMeta } from "F:/web/pages/index_hnkj.vue?macro=true";
import { default as agreementJs8SnD3KEzMeta } from "F:/web/pages/login/agreement.vue?macro=true";
import { default as indexcbk55GlZ0HMeta } from "F:/web/pages/login/index.vue?macro=true";
import { default as CategoryListzLT14kaS3bMeta } from "F:/web/pages/onlineExam/CategoryList.vue?macro=true";
import { default as OnlineExamDatao8enBMJ8OVMeta } from "F:/web/pages/onlineExam/OnlineExamData.vue?macro=true";
import { default as examUserInfoXI9MqEpqcjMeta } from "F:/web/pages/onlineExam/examUserInfo.vue?macro=true";
import { default as examView8kGbvW6Q6DMeta } from "F:/web/pages/onlineExam/examView.vue?macro=true";
import { default as indexIvlhuIoaUaMeta } from "F:/web/pages/onlineExam/index.vue?macro=true";
import { default as problemItemaHA6Y8Mb2ZMeta } from "F:/web/pages/onlineExam/problemItem.vue?macro=true";
import { default as resultVH6lavQCJkMeta } from "F:/web/pages/onlineExam/result.vue?macro=true";
import { default as signN2ZnLhnSXAMeta } from "F:/web/pages/onlineExam/sign.vue?macro=true";
import { default as startExamcrrJABZEcvMeta } from "F:/web/pages/onlineExam/startExam.vue?macro=true";
import { default as childrenListE36VNfnN6IMeta } from "F:/web/pages/paper/childrenList.vue?macro=true";
import { default as emigratedvIuQaTlPEmMeta } from "F:/web/pages/paper/emigrated.vue?macro=true";
import { default as indexn7WU6aJeJSMeta } from "F:/web/pages/paper/index.vue?macro=true";
import { default as listtLjX0VcUoxMeta } from "F:/web/pages/paper/list.vue?macro=true";
import { default as viewBMyQ7EYIgBMeta } from "F:/web/pages/paper/view.vue?macro=true";
import { default as contentdyRfDIsM1HMeta } from "F:/web/pages/partys/content.vue?macro=true";
import { default as indexKvqIUKLDW2Meta } from "F:/web/pages/partys/index.vue?macro=true";
import { default as buy83O2p8T2bWMeta } from "F:/web/pages/pay/buy.vue?macro=true";
import { default as paySuccess7432EFOI2zMeta } from "F:/web/pages/pay/paySuccess.vue?macro=true";
import { default as analysisF0merneOhrMeta } from "F:/web/pages/practice/analysis.vue?macro=true";
import { default as dailyTTtDOZzJjFMeta } from "F:/web/pages/practice/daily.vue?macro=true";
import { default as dailyProblem9UGsi5tgXbMeta } from "F:/web/pages/practice/dailyProblem.vue?macro=true";
import { default as errorDso5mJQRleMeta } from "F:/web/pages/practice/error.vue?macro=true";
import { default as errorAnalysis94gRsDPsXYMeta } from "F:/web/pages/practice/errorAnalysis.vue?macro=true";
import { default as errorProblemieDDAzxAwDMeta } from "F:/web/pages/practice/errorProblem.vue?macro=true";
import { default as errorResultMKSVrflwjhMeta } from "F:/web/pages/practice/errorResult.vue?macro=true";
import { default as resultvXq8jqcj2RMeta } from "F:/web/pages/practice/result.vue?macro=true";
import { default as startPracticeeV6kYJtrJ2Meta } from "F:/web/pages/practice/startPractice.vue?macro=true";
import { default as viewmYYvd4APuYMeta } from "F:/web/pages/practice/view.vue?macro=true";
import { default as indexGKI5Dre4fXMeta } from "F:/web/pages/register/index.vue?macro=true";
import { default as indexuv0joDBH2sMeta } from "F:/web/pages/resetpwd/index.vue?macro=true";
import { default as indexdAh9IF8TgLMeta } from "F:/web/pages/search/index.vue?macro=true";
import { default as indexpo8tArBzYlMeta } from "F:/web/pages/settlement/index.vue?macro=true";
import { default as listXivXoKzSZzMeta } from "F:/web/pages/teacher/list.vue?macro=true";
import { default as viewgqYgoStkl7Meta } from "F:/web/pages/teacher/view.vue?macro=true";
import { default as indexXVh9P1cqOjMeta } from "F:/web/pages/vip/index.vue?macro=true";
import { default as successk4zbE4me5DMeta } from "F:/web/pages/wx/success.vue?macro=true";
import { default as wxdFPVcN3HaqMeta } from "F:/web/pages/wx/wx.vue?macro=true";
export default [
  {
    name: certificateskgDtILOXgMeta?.name ?? "account-certificate",
    path: certificateskgDtILOXgMeta?.path ?? "/account/certificate",
    meta: certificateskgDtILOXgMeta || {},
    alias: certificateskgDtILOXgMeta?.alias || [],
    redirect: certificateskgDtILOXgMeta?.redirect || undefined,
    component: () => import("F:/web/pages/account/certificate.vue").then(m => m.default || m)
  },
  {
    name: collectHb0X7YGbnpMeta?.name ?? "account-collect",
    path: collectHb0X7YGbnpMeta?.path ?? "/account/collect",
    meta: collectHb0X7YGbnpMeta || {},
    alias: collectHb0X7YGbnpMeta?.alias || [],
    redirect: collectHb0X7YGbnpMeta?.redirect || undefined,
    component: () => import("F:/web/pages/account/collect.vue").then(m => m.default || m)
  },
  {
    name: coupongFs2enfvyZMeta?.name ?? "account-coupon",
    path: coupongFs2enfvyZMeta?.path ?? "/account/coupon",
    meta: coupongFs2enfvyZMeta || {},
    alias: coupongFs2enfvyZMeta?.alias || [],
    redirect: coupongFs2enfvyZMeta?.redirect || undefined,
    component: () => import("F:/web/pages/account/coupon.vue").then(m => m.default || m)
  },
  {
    name: courses6sTMZVyUHMeta?.name ?? "account-course",
    path: courses6sTMZVyUHMeta?.path ?? "/account/course",
    meta: courses6sTMZVyUHMeta || {},
    alias: courses6sTMZVyUHMeta?.alias || [],
    redirect: courses6sTMZVyUHMeta?.redirect || undefined,
    component: () => import("F:/web/pages/account/course.vue").then(m => m.default || m)
  },
  {
    name: examV0rtlt1O9pMeta?.name ?? "account-exam",
    path: examV0rtlt1O9pMeta?.path ?? "/account/exam",
    meta: examV0rtlt1O9pMeta || {},
    alias: examV0rtlt1O9pMeta?.alias || [],
    redirect: examV0rtlt1O9pMeta?.redirect || undefined,
    component: () => import("F:/web/pages/account/exam.vue").then(m => m.default || m)
  },
  {
    name: generalexamGWcJxAPLbsMeta?.name ?? "account-generalexam",
    path: generalexamGWcJxAPLbsMeta?.path ?? "/account/generalexam",
    meta: generalexamGWcJxAPLbsMeta || {},
    alias: generalexamGWcJxAPLbsMeta?.alias || [],
    redirect: generalexamGWcJxAPLbsMeta?.redirect || undefined,
    component: () => import("F:/web/pages/account/generalexam.vue").then(m => m.default || m)
  },
  {
    name: gradee6LZ5GvgDkMeta?.name ?? "account-grade",
    path: gradee6LZ5GvgDkMeta?.path ?? "/account/grade",
    meta: gradee6LZ5GvgDkMeta || {},
    alias: gradee6LZ5GvgDkMeta?.alias || [],
    redirect: gradee6LZ5GvgDkMeta?.redirect || undefined,
    component: () => import("F:/web/pages/account/grade.vue").then(m => m.default || m)
  },
  {
    name: homeworklybOEgbQZHMeta?.name ?? "account-homework",
    path: homeworklybOEgbQZHMeta?.path ?? "/account/homework",
    meta: homeworklybOEgbQZHMeta || {},
    alias: homeworklybOEgbQZHMeta?.alias || [],
    redirect: homeworklybOEgbQZHMeta?.redirect || undefined,
    component: () => import("F:/web/pages/account/homework.vue").then(m => m.default || m)
  },
  {
    name: infoZ07sIWFDc9Meta?.name ?? "account-info",
    path: infoZ07sIWFDc9Meta?.path ?? "/account/info",
    meta: infoZ07sIWFDc9Meta || {},
    alias: infoZ07sIWFDc9Meta?.alias || [],
    redirect: infoZ07sIWFDc9Meta?.redirect || undefined,
    component: () => import("F:/web/pages/account/info.vue").then(m => m.default || m)
  },
  {
    name: materialXWpVoRx6ZpMeta?.name ?? "account-material",
    path: materialXWpVoRx6ZpMeta?.path ?? "/account/material",
    meta: materialXWpVoRx6ZpMeta || {},
    alias: materialXWpVoRx6ZpMeta?.alias || [],
    redirect: materialXWpVoRx6ZpMeta?.redirect || undefined,
    component: () => import("F:/web/pages/account/material.vue").then(m => m.default || m)
  },
  {
    name: indexD5QxabqxJEMeta?.name ?? "account-message",
    path: indexD5QxabqxJEMeta?.path ?? "/account/message",
    meta: indexD5QxabqxJEMeta || {},
    alias: indexD5QxabqxJEMeta?.alias || [],
    redirect: indexD5QxabqxJEMeta?.redirect || undefined,
    component: () => import("F:/web/pages/account/message/index.vue").then(m => m.default || m)
  },
  {
    name: notekQt1IMV9ocMeta?.name ?? "account-note",
    path: notekQt1IMV9ocMeta?.path ?? "/account/note",
    meta: notekQt1IMV9ocMeta || {},
    alias: notekQt1IMV9ocMeta?.alias || [],
    redirect: notekQt1IMV9ocMeta?.redirect || undefined,
    component: () => import("F:/web/pages/account/note.vue").then(m => m.default || m)
  },
  {
    name: indexoEfduergfDMeta?.name ?? "account-order",
    path: indexoEfduergfDMeta?.path ?? "/account/order",
    meta: indexoEfduergfDMeta || {},
    alias: indexoEfduergfDMeta?.alias || [],
    redirect: indexoEfduergfDMeta?.redirect || undefined,
    component: () => import("F:/web/pages/account/order/index.vue").then(m => m.default || m)
  },
  {
    name: papergiA0TyRKu5Meta?.name ?? "account-paper",
    path: papergiA0TyRKu5Meta?.path ?? "/account/paper",
    meta: papergiA0TyRKu5Meta || {},
    alias: papergiA0TyRKu5Meta?.alias || [],
    redirect: papergiA0TyRKu5Meta?.redirect || undefined,
    component: () => import("F:/web/pages/account/paper.vue").then(m => m.default || m)
  },
  {
    name: scoreQvpcA8od9nMeta?.name ?? "account-score",
    path: scoreQvpcA8od9nMeta?.path ?? "/account/score",
    meta: scoreQvpcA8od9nMeta || {},
    alias: scoreQvpcA8od9nMeta?.alias || [],
    redirect: scoreQvpcA8od9nMeta?.redirect || undefined,
    component: () => import("F:/web/pages/account/score.vue").then(m => m.default || m)
  },
  {
    name: thesisRKwWO18mmSMeta?.name ?? "account-thesis",
    path: thesisRKwWO18mmSMeta?.path ?? "/account/thesis",
    meta: thesisRKwWO18mmSMeta || {},
    alias: thesisRKwWO18mmSMeta?.alias || [],
    redirect: thesisRKwWO18mmSMeta?.redirect || undefined,
    component: () => import("F:/web/pages/account/thesis.vue").then(m => m.default || m)
  },
  {
    name: aiSayOJYAxSrzXIMeta?.name ?? "ai-aiSay",
    path: aiSayOJYAxSrzXIMeta?.path ?? "/ai/aiSay",
    meta: aiSayOJYAxSrzXIMeta || {},
    alias: aiSayOJYAxSrzXIMeta?.alias || [],
    redirect: aiSayOJYAxSrzXIMeta?.redirect || undefined,
    component: () => import("F:/web/pages/ai/aiSay.vue").then(m => m.default || m)
  },
  {
    name: aiTalkDZcCNWWCivMeta?.name ?? "ai-aiTalk",
    path: aiTalkDZcCNWWCivMeta?.path ?? "/ai/aiTalk",
    meta: aiTalkDZcCNWWCivMeta || {},
    alias: aiTalkDZcCNWWCivMeta?.alias || [],
    redirect: aiTalkDZcCNWWCivMeta?.redirect || undefined,
    component: () => import("F:/web/pages/ai/aiTalk.vue").then(m => m.default || m)
  },
  {
    name: indexUFYC8pCTFxMeta?.name ?? "apply",
    path: indexUFYC8pCTFxMeta?.path ?? "/apply",
    meta: indexUFYC8pCTFxMeta || {},
    alias: indexUFYC8pCTFxMeta?.alias || [],
    redirect: indexUFYC8pCTFxMeta?.redirect || undefined,
    component: () => import("F:/web/pages/apply/index.vue").then(m => m.default || m)
  },
  {
    name: listHsDCDCSIReMeta?.name ?? "article-list",
    path: listHsDCDCSIReMeta?.path ?? "/article/list",
    meta: listHsDCDCSIReMeta || {},
    alias: listHsDCDCSIReMeta?.alias || [],
    redirect: listHsDCDCSIReMeta?.redirect || undefined,
    component: () => import("F:/web/pages/article/list.vue").then(m => m.default || m)
  },
  {
    name: viewJRbPsr31SWMeta?.name ?? "article-view",
    path: viewJRbPsr31SWMeta?.path ?? "/article/view",
    meta: viewJRbPsr31SWMeta || {},
    alias: viewJRbPsr31SWMeta?.alias || [],
    redirect: viewJRbPsr31SWMeta?.redirect || undefined,
    component: () => import("F:/web/pages/article/view.vue").then(m => m.default || m)
  },
  {
    name: SignNameX3DsLRdTwTMeta?.name ?? "contract-SignName",
    path: SignNameX3DsLRdTwTMeta?.path ?? "/contract/SignName",
    meta: SignNameX3DsLRdTwTMeta || {},
    alias: SignNameX3DsLRdTwTMeta?.alias || [],
    redirect: SignNameX3DsLRdTwTMeta?.redirect || undefined,
    component: () => import("F:/web/pages/contract/SignName.vue").then(m => m.default || m)
  },
  {
    name: indexI8q0cwWpsEMeta?.name ?? "contract",
    path: indexI8q0cwWpsEMeta?.path ?? "/contract",
    meta: indexI8q0cwWpsEMeta || {},
    alias: indexI8q0cwWpsEMeta?.alias || [],
    redirect: indexI8q0cwWpsEMeta?.redirect || undefined,
    component: () => import("F:/web/pages/contract/index.vue").then(m => m.default || m)
  },
  {
    name: signaturelfnJsbs6fdMeta?.name ?? "contract-signature",
    path: signaturelfnJsbs6fdMeta?.path ?? "/contract/signature",
    meta: signaturelfnJsbs6fdMeta || {},
    alias: signaturelfnJsbs6fdMeta?.alias || [],
    redirect: signaturelfnJsbs6fdMeta?.redirect || undefined,
    component: () => import("F:/web/pages/contract/signature.vue").then(m => m.default || m)
  },
  {
    name: audioyPZBy7RrbQMeta?.name ?? "course-audio",
    path: audioyPZBy7RrbQMeta?.path ?? "/course/audio",
    meta: audioyPZBy7RrbQMeta || {},
    alias: audioyPZBy7RrbQMeta?.alias || [],
    redirect: audioyPZBy7RrbQMeta?.redirect || undefined,
    component: () => import("F:/web/pages/course/audio.vue").then(m => m.default || m)
  },
  {
    name: combinezhCWY5LNjXMeta?.name ?? "course-combine",
    path: combinezhCWY5LNjXMeta?.path ?? "/course/combine",
    meta: combinezhCWY5LNjXMeta || {},
    alias: combinezhCWY5LNjXMeta?.alias || [],
    redirect: combinezhCWY5LNjXMeta?.redirect || undefined,
    component: () => import("F:/web/pages/course/combine.vue").then(m => m.default || m)
  },
  {
    name: contractDialogMcowl6GlCdMeta?.name ?? "course-contractDialog",
    path: contractDialogMcowl6GlCdMeta?.path ?? "/course/contractDialog",
    meta: contractDialogMcowl6GlCdMeta || {},
    alias: contractDialogMcowl6GlCdMeta?.alias || [],
    redirect: contractDialogMcowl6GlCdMeta?.redirect || undefined,
    component: () => import("F:/web/pages/course/contractDialog.vue").then(m => m.default || m)
  },
  {
    name: graphicaaC479eP0gMeta?.name ?? "course-graphic",
    path: graphicaaC479eP0gMeta?.path ?? "/course/graphic",
    meta: graphicaaC479eP0gMeta || {},
    alias: graphicaaC479eP0gMeta?.alias || [],
    redirect: graphicaaC479eP0gMeta?.redirect || undefined,
    component: () => import("F:/web/pages/course/graphic.vue").then(m => m.default || m)
  },
  {
    name: listpCQo1P09rHMeta?.name ?? "course-list",
    path: listpCQo1P09rHMeta?.path ?? "/course/list",
    meta: listpCQo1P09rHMeta || {},
    alias: listpCQo1P09rHMeta?.alias || [],
    redirect: listpCQo1P09rHMeta?.redirect || undefined,
    component: () => import("F:/web/pages/course/list.vue").then(m => m.default || m)
  },
  {
    name: liveEpvQ5aWZ5vMeta?.name ?? "course-live",
    path: liveEpvQ5aWZ5vMeta?.path ?? "/course/live",
    meta: liveEpvQ5aWZ5vMeta || {},
    alias: liveEpvQ5aWZ5vMeta?.alias || [],
    redirect: liveEpvQ5aWZ5vMeta?.redirect || undefined,
    component: () => import("F:/web/pages/course/live.vue").then(m => m.default || m)
  },
  {
    name: singlewuwnl0uihnMeta?.name ?? "course-single",
    path: singlewuwnl0uihnMeta?.path ?? "/course/single",
    meta: singlewuwnl0uihnMeta || {},
    alias: singlewuwnl0uihnMeta?.alias || [],
    redirect: singlewuwnl0uihnMeta?.redirect || undefined,
    component: () => import("F:/web/pages/course/single.vue").then(m => m.default || m)
  },
  {
    name: subjectwfiIA1Lok7Meta?.name ?? "course-subject",
    path: subjectwfiIA1Lok7Meta?.path ?? "/course/subject",
    meta: subjectwfiIA1Lok7Meta || {},
    alias: subjectwfiIA1Lok7Meta?.alias || [],
    redirect: subjectwfiIA1Lok7Meta?.redirect || undefined,
    component: () => import("F:/web/pages/course/subject.vue").then(m => m.default || m)
  },
  {
    name: topicSb95cltA58Meta?.name ?? "course-topic",
    path: topicSb95cltA58Meta?.path ?? "/course/topic",
    meta: topicSb95cltA58Meta || {},
    alias: topicSb95cltA58Meta?.alias || [],
    redirect: topicSb95cltA58Meta?.redirect || undefined,
    component: () => import("F:/web/pages/course/topic.vue").then(m => m.default || m)
  },
  {
    name: videoCkFMfWQ5dAMeta?.name ?? "course-video",
    path: videoCkFMfWQ5dAMeta?.path ?? "/course/video",
    meta: videoCkFMfWQ5dAMeta || {},
    alias: videoCkFMfWQ5dAMeta?.alias || [],
    redirect: videoCkFMfWQ5dAMeta?.redirect || undefined,
    component: () => import("F:/web/pages/course/video.vue").then(m => m.default || m)
  },
  {
    name: EntrancetestHeadO4DvfzGvvNMeta?.name ?? "entrancetest-EntrancetestHead",
    path: EntrancetestHeadO4DvfzGvvNMeta?.path ?? "/entrancetest/EntrancetestHead",
    meta: EntrancetestHeadO4DvfzGvvNMeta || {},
    alias: EntrancetestHeadO4DvfzGvvNMeta?.alias || [],
    redirect: EntrancetestHeadO4DvfzGvvNMeta?.redirect || undefined,
    component: () => import("F:/web/pages/entrancetest/EntrancetestHead.vue").then(m => m.default || m)
  },
  {
    name: SubmitSuccessncQAi7zIpJMeta?.name ?? "entrancetest-SubmitSuccess",
    path: SubmitSuccessncQAi7zIpJMeta?.path ?? "/entrancetest/SubmitSuccess",
    meta: SubmitSuccessncQAi7zIpJMeta || {},
    alias: SubmitSuccessncQAi7zIpJMeta?.alias || [],
    redirect: SubmitSuccessncQAi7zIpJMeta?.redirect || undefined,
    component: () => import("F:/web/pages/entrancetest/SubmitSuccess.vue").then(m => m.default || m)
  },
  {
    name: TestItemuGfvRalQNHMeta?.name ?? "entrancetest-TestItem",
    path: TestItemuGfvRalQNHMeta?.path ?? "/entrancetest/TestItem",
    meta: TestItemuGfvRalQNHMeta || {},
    alias: TestItemuGfvRalQNHMeta?.alias || [],
    redirect: TestItemuGfvRalQNHMeta?.redirect || undefined,
    component: () => import("F:/web/pages/entrancetest/TestItem.vue").then(m => m.default || m)
  },
  {
    name: exam6t9viuB86JMeta?.name ?? "entrancetest-exam",
    path: exam6t9viuB86JMeta?.path ?? "/entrancetest/exam",
    meta: exam6t9viuB86JMeta || {},
    alias: exam6t9viuB86JMeta?.alias || [],
    redirect: exam6t9viuB86JMeta?.redirect || undefined,
    component: () => import("F:/web/pages/entrancetest/exam.vue").then(m => m.default || m)
  },
  {
    name: indexXRiX2TnSURMeta?.name ?? "entrancetest",
    path: indexXRiX2TnSURMeta?.path ?? "/entrancetest",
    meta: indexXRiX2TnSURMeta || {},
    alias: indexXRiX2TnSURMeta?.alias || [],
    redirect: indexXRiX2TnSURMeta?.redirect || undefined,
    component: () => import("F:/web/pages/entrancetest/index.vue").then(m => m.default || m)
  },
  {
    name: reportsfBAYIFGxAMeta?.name ?? "entrancetest-report",
    path: reportsfBAYIFGxAMeta?.path ?? "/entrancetest/report",
    meta: reportsfBAYIFGxAMeta || {},
    alias: reportsfBAYIFGxAMeta?.alias || [],
    redirect: reportsfBAYIFGxAMeta?.redirect || undefined,
    component: () => import("F:/web/pages/entrancetest/report.vue").then(m => m.default || m)
  },
  {
    name: analysise8AywoaF5gMeta?.name ?? "exam-analysis",
    path: analysise8AywoaF5gMeta?.path ?? "/exam/analysis",
    meta: analysise8AywoaF5gMeta || {},
    alias: analysise8AywoaF5gMeta?.alias || [],
    redirect: analysise8AywoaF5gMeta?.redirect || undefined,
    component: () => import("F:/web/pages/exam/analysis.vue").then(m => m.default || m)
  },
  {
    name: emigratedyi68sMeQqBMeta?.name ?? "exam-emigrated",
    path: emigratedyi68sMeQqBMeta?.path ?? "/exam/emigrated",
    meta: emigratedyi68sMeQqBMeta || {},
    alias: emigratedyi68sMeQqBMeta?.alias || [],
    redirect: emigratedyi68sMeQqBMeta?.redirect || undefined,
    component: () => import("F:/web/pages/exam/emigrated.vue").then(m => m.default || m)
  },
  {
    name: indexRcYyPkolKhMeta?.name ?? "exam",
    path: indexRcYyPkolKhMeta?.path ?? "/exam",
    meta: indexRcYyPkolKhMeta || {},
    alias: indexRcYyPkolKhMeta?.alias || [],
    redirect: indexRcYyPkolKhMeta?.redirect || undefined,
    component: () => import("F:/web/pages/exam/index.vue").then(m => m.default || m)
  },
  {
    name: listY9sNhLgdQDMeta?.name ?? "exam-list",
    path: listY9sNhLgdQDMeta?.path ?? "/exam/list",
    meta: listY9sNhLgdQDMeta || {},
    alias: listY9sNhLgdQDMeta?.alias || [],
    redirect: listY9sNhLgdQDMeta?.redirect || undefined,
    component: () => import("F:/web/pages/exam/list.vue").then(m => m.default || m)
  },
  {
    name: resultsze2OKmNPiMeta?.name ?? "exam-result",
    path: resultsze2OKmNPiMeta?.path ?? "/exam/result",
    meta: resultsze2OKmNPiMeta || {},
    alias: resultsze2OKmNPiMeta?.alias || [],
    redirect: resultsze2OKmNPiMeta?.redirect || undefined,
    component: () => import("F:/web/pages/exam/result.vue").then(m => m.default || m)
  },
  {
    name: startExammabOEQs39MMeta?.name ?? "exam-startExam",
    path: startExammabOEQs39MMeta?.path ?? "/exam/startExam",
    meta: startExammabOEQs39MMeta || {},
    alias: startExammabOEQs39MMeta?.alias || [],
    redirect: startExammabOEQs39MMeta?.redirect || undefined,
    component: () => import("F:/web/pages/exam/startExam.vue").then(m => m.default || m)
  },
  {
    name: homeworkZN0W8YuXPmMeta?.name ?? "grade-homework",
    path: homeworkZN0W8YuXPmMeta?.path ?? "/grade/homework",
    meta: homeworkZN0W8YuXPmMeta || {},
    alias: homeworkZN0W8YuXPmMeta?.alias || [],
    redirect: homeworkZN0W8YuXPmMeta?.redirect || undefined,
    component: () => import("F:/web/pages/grade/homework.vue").then(m => m.default || m)
  },
  {
    name: listJbDygZMHUDMeta?.name ?? "grade-list",
    path: listJbDygZMHUDMeta?.path ?? "/grade/list",
    meta: listJbDygZMHUDMeta || {},
    alias: listJbDygZMHUDMeta?.alias || [],
    redirect: listJbDygZMHUDMeta?.redirect || undefined,
    component: () => import("F:/web/pages/grade/list.vue").then(m => m.default || m)
  },
  {
    name: material0OtTWMpsAeMeta?.name ?? "grade-material",
    path: material0OtTWMpsAeMeta?.path ?? "/grade/material",
    meta: material0OtTWMpsAeMeta || {},
    alias: material0OtTWMpsAeMeta?.alias || [],
    redirect: material0OtTWMpsAeMeta?.redirect || undefined,
    component: () => import("F:/web/pages/grade/material.vue").then(m => m.default || m)
  },
  {
    name: practiceWK4JnLO22dMeta?.name ?? "grade-practice",
    path: practiceWK4JnLO22dMeta?.path ?? "/grade/practice",
    meta: practiceWK4JnLO22dMeta || {},
    alias: practiceWK4JnLO22dMeta?.alias || [],
    redirect: practiceWK4JnLO22dMeta?.redirect || undefined,
    component: () => import("F:/web/pages/grade/practice.vue").then(m => m.default || m)
  },
  {
    name: viewppsA2hot8sMeta?.name ?? "grade-view",
    path: viewppsA2hot8sMeta?.path ?? "/grade/view",
    meta: viewppsA2hot8sMeta || {},
    alias: viewppsA2hot8sMeta?.alias || [],
    redirect: viewppsA2hot8sMeta?.redirect || undefined,
    component: () => import("F:/web/pages/grade/view.vue").then(m => m.default || m)
  },
  {
    name: indexatGbTRBqxAMeta?.name ?? "guide",
    path: indexatGbTRBqxAMeta?.path ?? "/guide",
    meta: indexatGbTRBqxAMeta || {},
    alias: indexatGbTRBqxAMeta?.alias || [],
    redirect: indexatGbTRBqxAMeta?.redirect || undefined,
    component: () => import("F:/web/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: indexJC8TYTmsskMeta?.name ?? "index",
    path: indexJC8TYTmsskMeta?.path ?? "/",
    meta: indexJC8TYTmsskMeta || {},
    alias: indexJC8TYTmsskMeta?.alias || [],
    redirect: indexJC8TYTmsskMeta?.redirect || undefined,
    component: () => import("F:/web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index_hnkj9U2RmNVXVyMeta?.name ?? "index_hnkj",
    path: index_hnkj9U2RmNVXVyMeta?.path ?? "/index_hnkj",
    meta: index_hnkj9U2RmNVXVyMeta || {},
    alias: index_hnkj9U2RmNVXVyMeta?.alias || [],
    redirect: index_hnkj9U2RmNVXVyMeta?.redirect || undefined,
    component: () => import("F:/web/pages/index_hnkj.vue").then(m => m.default || m)
  },
  {
    name: agreementJs8SnD3KEzMeta?.name ?? "login-agreement",
    path: agreementJs8SnD3KEzMeta?.path ?? "/login/agreement",
    meta: agreementJs8SnD3KEzMeta || {},
    alias: agreementJs8SnD3KEzMeta?.alias || [],
    redirect: agreementJs8SnD3KEzMeta?.redirect || undefined,
    component: () => import("F:/web/pages/login/agreement.vue").then(m => m.default || m)
  },
  {
    name: indexcbk55GlZ0HMeta?.name ?? "login",
    path: indexcbk55GlZ0HMeta?.path ?? "/login",
    meta: indexcbk55GlZ0HMeta || {},
    alias: indexcbk55GlZ0HMeta?.alias || [],
    redirect: indexcbk55GlZ0HMeta?.redirect || undefined,
    component: () => import("F:/web/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: CategoryListzLT14kaS3bMeta?.name ?? "onlineExam-CategoryList",
    path: CategoryListzLT14kaS3bMeta?.path ?? "/onlineExam/CategoryList",
    meta: CategoryListzLT14kaS3bMeta || {},
    alias: CategoryListzLT14kaS3bMeta?.alias || [],
    redirect: CategoryListzLT14kaS3bMeta?.redirect || undefined,
    component: () => import("F:/web/pages/onlineExam/CategoryList.vue").then(m => m.default || m)
  },
  {
    name: OnlineExamDatao8enBMJ8OVMeta?.name ?? "onlineExam-OnlineExamData",
    path: OnlineExamDatao8enBMJ8OVMeta?.path ?? "/onlineExam/OnlineExamData",
    meta: OnlineExamDatao8enBMJ8OVMeta || {},
    alias: OnlineExamDatao8enBMJ8OVMeta?.alias || [],
    redirect: OnlineExamDatao8enBMJ8OVMeta?.redirect || undefined,
    component: () => import("F:/web/pages/onlineExam/OnlineExamData.vue").then(m => m.default || m)
  },
  {
    name: examUserInfoXI9MqEpqcjMeta?.name ?? "onlineExam-examUserInfo",
    path: examUserInfoXI9MqEpqcjMeta?.path ?? "/onlineExam/examUserInfo",
    meta: examUserInfoXI9MqEpqcjMeta || {},
    alias: examUserInfoXI9MqEpqcjMeta?.alias || [],
    redirect: examUserInfoXI9MqEpqcjMeta?.redirect || undefined,
    component: () => import("F:/web/pages/onlineExam/examUserInfo.vue").then(m => m.default || m)
  },
  {
    name: examView8kGbvW6Q6DMeta?.name ?? "onlineExam-examView",
    path: examView8kGbvW6Q6DMeta?.path ?? "/onlineExam/examView",
    meta: examView8kGbvW6Q6DMeta || {},
    alias: examView8kGbvW6Q6DMeta?.alias || [],
    redirect: examView8kGbvW6Q6DMeta?.redirect || undefined,
    component: () => import("F:/web/pages/onlineExam/examView.vue").then(m => m.default || m)
  },
  {
    name: indexIvlhuIoaUaMeta?.name ?? "onlineExam",
    path: indexIvlhuIoaUaMeta?.path ?? "/onlineExam",
    meta: indexIvlhuIoaUaMeta || {},
    alias: indexIvlhuIoaUaMeta?.alias || [],
    redirect: indexIvlhuIoaUaMeta?.redirect || undefined,
    component: () => import("F:/web/pages/onlineExam/index.vue").then(m => m.default || m)
  },
  {
    name: problemItemaHA6Y8Mb2ZMeta?.name ?? "onlineExam-problemItem",
    path: problemItemaHA6Y8Mb2ZMeta?.path ?? "/onlineExam/problemItem",
    meta: problemItemaHA6Y8Mb2ZMeta || {},
    alias: problemItemaHA6Y8Mb2ZMeta?.alias || [],
    redirect: problemItemaHA6Y8Mb2ZMeta?.redirect || undefined,
    component: () => import("F:/web/pages/onlineExam/problemItem.vue").then(m => m.default || m)
  },
  {
    name: resultVH6lavQCJkMeta?.name ?? "onlineExam-result",
    path: resultVH6lavQCJkMeta?.path ?? "/onlineExam/result",
    meta: resultVH6lavQCJkMeta || {},
    alias: resultVH6lavQCJkMeta?.alias || [],
    redirect: resultVH6lavQCJkMeta?.redirect || undefined,
    component: () => import("F:/web/pages/onlineExam/result.vue").then(m => m.default || m)
  },
  {
    name: signN2ZnLhnSXAMeta?.name ?? "onlineExam-sign",
    path: signN2ZnLhnSXAMeta?.path ?? "/onlineExam/sign",
    meta: signN2ZnLhnSXAMeta || {},
    alias: signN2ZnLhnSXAMeta?.alias || [],
    redirect: signN2ZnLhnSXAMeta?.redirect || undefined,
    component: () => import("F:/web/pages/onlineExam/sign.vue").then(m => m.default || m)
  },
  {
    name: startExamcrrJABZEcvMeta?.name ?? "onlineExam-startExam",
    path: startExamcrrJABZEcvMeta?.path ?? "/onlineExam/startExam",
    meta: startExamcrrJABZEcvMeta || {},
    alias: startExamcrrJABZEcvMeta?.alias || [],
    redirect: startExamcrrJABZEcvMeta?.redirect || undefined,
    component: () => import("F:/web/pages/onlineExam/startExam.vue").then(m => m.default || m)
  },
  {
    name: childrenListE36VNfnN6IMeta?.name ?? "paper-childrenList",
    path: childrenListE36VNfnN6IMeta?.path ?? "/paper/childrenList",
    meta: childrenListE36VNfnN6IMeta || {},
    alias: childrenListE36VNfnN6IMeta?.alias || [],
    redirect: childrenListE36VNfnN6IMeta?.redirect || undefined,
    component: () => import("F:/web/pages/paper/childrenList.vue").then(m => m.default || m)
  },
  {
    name: emigratedvIuQaTlPEmMeta?.name ?? "paper-emigrated",
    path: emigratedvIuQaTlPEmMeta?.path ?? "/paper/emigrated",
    meta: emigratedvIuQaTlPEmMeta || {},
    alias: emigratedvIuQaTlPEmMeta?.alias || [],
    redirect: emigratedvIuQaTlPEmMeta?.redirect || undefined,
    component: () => import("F:/web/pages/paper/emigrated.vue").then(m => m.default || m)
  },
  {
    name: indexn7WU6aJeJSMeta?.name ?? "paper",
    path: indexn7WU6aJeJSMeta?.path ?? "/paper",
    meta: indexn7WU6aJeJSMeta || {},
    alias: indexn7WU6aJeJSMeta?.alias || [],
    redirect: indexn7WU6aJeJSMeta?.redirect || undefined,
    component: () => import("F:/web/pages/paper/index.vue").then(m => m.default || m)
  },
  {
    name: listtLjX0VcUoxMeta?.name ?? "paper-list",
    path: listtLjX0VcUoxMeta?.path ?? "/paper/list",
    meta: listtLjX0VcUoxMeta || {},
    alias: listtLjX0VcUoxMeta?.alias || [],
    redirect: listtLjX0VcUoxMeta?.redirect || undefined,
    component: () => import("F:/web/pages/paper/list.vue").then(m => m.default || m)
  },
  {
    name: viewBMyQ7EYIgBMeta?.name ?? "paper-view",
    path: viewBMyQ7EYIgBMeta?.path ?? "/paper/view",
    meta: viewBMyQ7EYIgBMeta || {},
    alias: viewBMyQ7EYIgBMeta?.alias || [],
    redirect: viewBMyQ7EYIgBMeta?.redirect || undefined,
    component: () => import("F:/web/pages/paper/view.vue").then(m => m.default || m)
  },
  {
    name: contentdyRfDIsM1HMeta?.name ?? "partys-content",
    path: contentdyRfDIsM1HMeta?.path ?? "/partys/content",
    meta: contentdyRfDIsM1HMeta || {},
    alias: contentdyRfDIsM1HMeta?.alias || [],
    redirect: contentdyRfDIsM1HMeta?.redirect || undefined,
    component: () => import("F:/web/pages/partys/content.vue").then(m => m.default || m)
  },
  {
    name: indexKvqIUKLDW2Meta?.name ?? "partys",
    path: indexKvqIUKLDW2Meta?.path ?? "/partys",
    meta: indexKvqIUKLDW2Meta || {},
    alias: indexKvqIUKLDW2Meta?.alias || [],
    redirect: indexKvqIUKLDW2Meta?.redirect || undefined,
    component: () => import("F:/web/pages/partys/index.vue").then(m => m.default || m)
  },
  {
    name: buy83O2p8T2bWMeta?.name ?? "pay-buy",
    path: buy83O2p8T2bWMeta?.path ?? "/pay/buy",
    meta: buy83O2p8T2bWMeta || {},
    alias: buy83O2p8T2bWMeta?.alias || [],
    redirect: buy83O2p8T2bWMeta?.redirect || undefined,
    component: () => import("F:/web/pages/pay/buy.vue").then(m => m.default || m)
  },
  {
    name: paySuccess7432EFOI2zMeta?.name ?? "pay-paySuccess",
    path: paySuccess7432EFOI2zMeta?.path ?? "/pay/paySuccess",
    meta: paySuccess7432EFOI2zMeta || {},
    alias: paySuccess7432EFOI2zMeta?.alias || [],
    redirect: paySuccess7432EFOI2zMeta?.redirect || undefined,
    component: () => import("F:/web/pages/pay/paySuccess.vue").then(m => m.default || m)
  },
  {
    name: analysisF0merneOhrMeta?.name ?? "practice-analysis",
    path: analysisF0merneOhrMeta?.path ?? "/practice/analysis",
    meta: analysisF0merneOhrMeta || {},
    alias: analysisF0merneOhrMeta?.alias || [],
    redirect: analysisF0merneOhrMeta?.redirect || undefined,
    component: () => import("F:/web/pages/practice/analysis.vue").then(m => m.default || m)
  },
  {
    name: dailyTTtDOZzJjFMeta?.name ?? "practice-daily",
    path: dailyTTtDOZzJjFMeta?.path ?? "/practice/daily",
    meta: dailyTTtDOZzJjFMeta || {},
    alias: dailyTTtDOZzJjFMeta?.alias || [],
    redirect: dailyTTtDOZzJjFMeta?.redirect || undefined,
    component: () => import("F:/web/pages/practice/daily.vue").then(m => m.default || m)
  },
  {
    name: dailyProblem9UGsi5tgXbMeta?.name ?? "practice-dailyProblem",
    path: dailyProblem9UGsi5tgXbMeta?.path ?? "/practice/dailyProblem",
    meta: dailyProblem9UGsi5tgXbMeta || {},
    alias: dailyProblem9UGsi5tgXbMeta?.alias || [],
    redirect: dailyProblem9UGsi5tgXbMeta?.redirect || undefined,
    component: () => import("F:/web/pages/practice/dailyProblem.vue").then(m => m.default || m)
  },
  {
    name: errorDso5mJQRleMeta?.name ?? "practice-error",
    path: errorDso5mJQRleMeta?.path ?? "/practice/error",
    meta: errorDso5mJQRleMeta || {},
    alias: errorDso5mJQRleMeta?.alias || [],
    redirect: errorDso5mJQRleMeta?.redirect || undefined,
    component: () => import("F:/web/pages/practice/error.vue").then(m => m.default || m)
  },
  {
    name: errorAnalysis94gRsDPsXYMeta?.name ?? "practice-errorAnalysis",
    path: errorAnalysis94gRsDPsXYMeta?.path ?? "/practice/errorAnalysis",
    meta: errorAnalysis94gRsDPsXYMeta || {},
    alias: errorAnalysis94gRsDPsXYMeta?.alias || [],
    redirect: errorAnalysis94gRsDPsXYMeta?.redirect || undefined,
    component: () => import("F:/web/pages/practice/errorAnalysis.vue").then(m => m.default || m)
  },
  {
    name: errorProblemieDDAzxAwDMeta?.name ?? "practice-errorProblem",
    path: errorProblemieDDAzxAwDMeta?.path ?? "/practice/errorProblem",
    meta: errorProblemieDDAzxAwDMeta || {},
    alias: errorProblemieDDAzxAwDMeta?.alias || [],
    redirect: errorProblemieDDAzxAwDMeta?.redirect || undefined,
    component: () => import("F:/web/pages/practice/errorProblem.vue").then(m => m.default || m)
  },
  {
    name: errorResultMKSVrflwjhMeta?.name ?? "practice-errorResult",
    path: errorResultMKSVrflwjhMeta?.path ?? "/practice/errorResult",
    meta: errorResultMKSVrflwjhMeta || {},
    alias: errorResultMKSVrflwjhMeta?.alias || [],
    redirect: errorResultMKSVrflwjhMeta?.redirect || undefined,
    component: () => import("F:/web/pages/practice/errorResult.vue").then(m => m.default || m)
  },
  {
    name: resultvXq8jqcj2RMeta?.name ?? "practice-result",
    path: resultvXq8jqcj2RMeta?.path ?? "/practice/result",
    meta: resultvXq8jqcj2RMeta || {},
    alias: resultvXq8jqcj2RMeta?.alias || [],
    redirect: resultvXq8jqcj2RMeta?.redirect || undefined,
    component: () => import("F:/web/pages/practice/result.vue").then(m => m.default || m)
  },
  {
    name: startPracticeeV6kYJtrJ2Meta?.name ?? "practice-startPractice",
    path: startPracticeeV6kYJtrJ2Meta?.path ?? "/practice/startPractice",
    meta: startPracticeeV6kYJtrJ2Meta || {},
    alias: startPracticeeV6kYJtrJ2Meta?.alias || [],
    redirect: startPracticeeV6kYJtrJ2Meta?.redirect || undefined,
    component: () => import("F:/web/pages/practice/startPractice.vue").then(m => m.default || m)
  },
  {
    name: viewmYYvd4APuYMeta?.name ?? "practice-view",
    path: viewmYYvd4APuYMeta?.path ?? "/practice/view",
    meta: viewmYYvd4APuYMeta || {},
    alias: viewmYYvd4APuYMeta?.alias || [],
    redirect: viewmYYvd4APuYMeta?.redirect || undefined,
    component: () => import("F:/web/pages/practice/view.vue").then(m => m.default || m)
  },
  {
    name: indexGKI5Dre4fXMeta?.name ?? "register",
    path: indexGKI5Dre4fXMeta?.path ?? "/register",
    meta: indexGKI5Dre4fXMeta || {},
    alias: indexGKI5Dre4fXMeta?.alias || [],
    redirect: indexGKI5Dre4fXMeta?.redirect || undefined,
    component: () => import("F:/web/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexuv0joDBH2sMeta?.name ?? "resetpwd",
    path: indexuv0joDBH2sMeta?.path ?? "/resetpwd",
    meta: indexuv0joDBH2sMeta || {},
    alias: indexuv0joDBH2sMeta?.alias || [],
    redirect: indexuv0joDBH2sMeta?.redirect || undefined,
    component: () => import("F:/web/pages/resetpwd/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAh9IF8TgLMeta?.name ?? "search",
    path: indexdAh9IF8TgLMeta?.path ?? "/search",
    meta: indexdAh9IF8TgLMeta || {},
    alias: indexdAh9IF8TgLMeta?.alias || [],
    redirect: indexdAh9IF8TgLMeta?.redirect || undefined,
    component: () => import("F:/web/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexpo8tArBzYlMeta?.name ?? "settlement",
    path: indexpo8tArBzYlMeta?.path ?? "/settlement",
    meta: indexpo8tArBzYlMeta || {},
    alias: indexpo8tArBzYlMeta?.alias || [],
    redirect: indexpo8tArBzYlMeta?.redirect || undefined,
    component: () => import("F:/web/pages/settlement/index.vue").then(m => m.default || m)
  },
  {
    name: listXivXoKzSZzMeta?.name ?? "teacher-list",
    path: listXivXoKzSZzMeta?.path ?? "/teacher/list",
    meta: listXivXoKzSZzMeta || {},
    alias: listXivXoKzSZzMeta?.alias || [],
    redirect: listXivXoKzSZzMeta?.redirect || undefined,
    component: () => import("F:/web/pages/teacher/list.vue").then(m => m.default || m)
  },
  {
    name: viewgqYgoStkl7Meta?.name ?? "teacher-view",
    path: viewgqYgoStkl7Meta?.path ?? "/teacher/view",
    meta: viewgqYgoStkl7Meta || {},
    alias: viewgqYgoStkl7Meta?.alias || [],
    redirect: viewgqYgoStkl7Meta?.redirect || undefined,
    component: () => import("F:/web/pages/teacher/view.vue").then(m => m.default || m)
  },
  {
    name: indexXVh9P1cqOjMeta?.name ?? "vip",
    path: indexXVh9P1cqOjMeta?.path ?? "/vip",
    meta: indexXVh9P1cqOjMeta || {},
    alias: indexXVh9P1cqOjMeta?.alias || [],
    redirect: indexXVh9P1cqOjMeta?.redirect || undefined,
    component: () => import("F:/web/pages/vip/index.vue").then(m => m.default || m)
  },
  {
    name: successk4zbE4me5DMeta?.name ?? "wx-success",
    path: successk4zbE4me5DMeta?.path ?? "/wx/success",
    meta: successk4zbE4me5DMeta || {},
    alias: successk4zbE4me5DMeta?.alias || [],
    redirect: successk4zbE4me5DMeta?.redirect || undefined,
    component: () => import("F:/web/pages/wx/success.vue").then(m => m.default || m)
  },
  {
    name: wxdFPVcN3HaqMeta?.name ?? "wx-wx",
    path: wxdFPVcN3HaqMeta?.path ?? "/wx/wx",
    meta: wxdFPVcN3HaqMeta || {},
    alias: wxdFPVcN3HaqMeta?.alias || [],
    redirect: wxdFPVcN3HaqMeta?.redirect || undefined,
    component: () => import("F:/web/pages/wx/wx.vue").then(m => m.default || m)
  }
]