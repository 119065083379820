<template>
  <div v-if="token">
    <el-dialog
      v-model="map.showContract"
      custom-class="footer_border_dialog"
      title="阅读协议"
      :width="840"
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :destroy-on-close="true"
      :show-close="false"
    >
      <div class="contract" @scroll="scroll">
        <el-scrollbar class="contract-body">
          <div v-html="map.info.contractBody"></div>
        </el-scrollbar>
      </div>
      <template #footer>
        <div class="footer_dialog">
          <el-checkbox
            style="margin-left: 20px; z-index: 999"
            v-model="map.isAgreement"
            :disabled="!map.isContractRead"
            size="default"
          >
            我已阅读并同意《阅读协议》
          </el-checkbox>
          <el-button
            type="primary"
            size="large"
            :disabled="!map.isAgreement"
            @click="handleClose"
          >
            已确认
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { computed, ref, onMounted, onUnmounted, reactive, watch } from "vue";
import { setStore } from "@/utils/storage";
import { useUserStore } from "@/stores/user";
import { getUserContract } from "@/api/user";
import { saveContract } from "@/api/course";
const store = useUserStore();
const { getToken, userView } = store;
const token = computed(() => {
  return getToken();
});
const map = reactive({
  info: {},
  showContract: false,
  isAgreement: false,
  isContractRead: false,
});
const handleClose = () => {
  map.showContract = false;
  saveContract({
    contractId: map.info.id,
    readed: map.isContractRead,
  }).then((res) => {
    let sessData = userInfo();
    sessData.isReadContract = true;
    setSession("userInfo", sessData);
  });
};
const scroll = (event) => {
  const { scrollTop, scrollHeight, clientHeight } = event.target;
  // 判断是否滚动到底部
  const isBottom = scrollHeight - (scrollTop + clientHeight) < 10; // 这里的1是一个阈值，可以根据需要调整
  if (isBottom) {
    map.isContractRead = true;
  }
};
const userInfo = () => {
  let sessData = getSession("userInfo");
  if (sessData && typeof sessData == "string") {
    sessData = JSON.parse(sessData);
  }
  return sessData;
};
onMounted(() => {
  if (token.value) {
    let sessData = userInfo();
    if (sessData && !sessData.isReadContract) {
      getUserContract({}).then((res) => {
        if (res && res.contractBody) {
          map.info = res;
          map.showContract = true;
        }
      });
    }
  }
});
</script>
<style lang="scss" scoped>
.preview_dialog {
  .el-dialog__footer {
    padding: 0;
  }
}
.contract {
  overflow: auto;
  width: 100%;
  max-height: 530px;
}
.contract-body {
  width: 21cm; /* A4宽度 */
  // height: 98%;
  // padding: 2cm;
  margin: auto;
  border-radius: 5px;
}
.footer_dialog {
  align-items: center;
  display: flex;
  font-size: 12px;
  justify-content: space-between;
}
</style>
