<template>
  <div class="page-index" v-if="isDomain">
    <div class="component_layout"><index /></div>
  </div>
  <NuxtLayout v-else>
    <div class="page-index">
      <div v-for="element in componentList" :key="element.id" class="component_layout">
        <index-banner v-if="element.type === 'banner'" :component-info="element" />
        <!-- 课程 -->
        <index-course v-if="element.type === 'course' && domain != 'hnkdtdxlkspx.ykumda.com'" :component-info="element" />
        <course-hnkj  v-if="element.type === 'course' && domain == 'hnkdtdxlkspx.ykumda.com'" :component-info="element"/>
        <index-grade v-if="element.type === 'grade'" :component-info="element" />
        <!-- 教师/团队 -->
        <index-teacher v-if="element.type === 'teacher' && domain != 'hnkdtdxlkspx.ykumda.com'" :component-info="element" />
        <teacher-hnkj v-if="element.type === 'teacher' && domain == 'hnkdtdxlkspx.ykumda.com'" :component-info="element" />
        <index-news v-if="element.type === 'news'" :component-info="element" />
        <index-ad v-if="element.type === 'ad'" :component-info="element" />
        <!-- 广告banner -->
        <index-patch-ad v-if="element.type === 'patchAd'" :component-info="element" />
        <index-advantage v-if="element.type === 'advantage'" :component-info="element" />
        <!-- 直播 -->
        <index-live v-if="element.type === 'live' && domain != 'hnkdtdxlkspx.ykumda.com'" :component-info="element" />
        <live-hnkj v-if="element.type === 'live' && domain == 'hnkdtdxlkspx.ykumda.com'" :component-info="element" />
      </div>
      <material v-if="isDomain1" />
      <!-- <material /> -->
      <readContract />
    </div>
  </NuxtLayout>
</template>
<script setup>
import index from "./onlineExam/index.vue";
import material from "@/components/material/index";
import readContract from "@/components/readContract/index";
import { checkContractExist } from "@/api/user";
import { getComponentList, getComponentData } from "@/api/layouts";
import cookie from "@/utils/cookies";
import config from "~/config";
import { shopDataInfo } from "../api/system";
import liveHnkj from "@/components/index/Live/indexHnkj.vue";
import courseHnkj from "@/components/index/Course/indexHnkj.vue";
import teacherHnkj from "@/components/index/Teacher/indexHnkj.vue";
const router = useRouter();
const { data: shopData } = await useAsyncData("course", async (ctx) => {
  let url = "";

  if (process.server) {
    url = ctx.ssrContext?.event?.req?.headers?.host;
  } else {
    url = window.location.host;
  }
  if (config.url) {
    url = config.url;
  }
  return await shopDataInfo({ domain: url });
});


const cookieData = cookie.getShop();

useHead({
  meta: [
    { charset: "utf-8" },
    {
      hid: "keywords",
      name: "keywords",
      content: shopData.value.keywords || cookieData.shopName,
    },
    {
      hid: "description",
      name: "description",
      content: shopData.value.shopIntroduce || cookieData.shopName,
    },
  ],
  title: computed(() => `${cookieData.shopName}`),
});

const opts = reactive({
  componentEnum: {
    1: { name: "banner", listName: "detailList" },
    2: { name: "course", listName: "courseList" },
    3: { name: "grade", listName: "classroomList" },
    4: { name: "teacher", listName: "teacherList" },
    5: { name: "news", listName: "articleList" },
    6: { name: "ad", listName: "detailList" },
    7: { name: "patchAd", listName: "detailList" },
    9: { name: "advantage", listName: "detailList" },
    10: { name: "live", listName: "courseList" },
  },
});
const sessData1 = ref({});
const domain = ref("");
const componentList = ref([]);
const getTemplate = async () => {
  const result = await getComponentList();
  componentList.value = [];
  result.forEach((item) => {
    const c = opts.componentEnum[item.componentType];
    if (c && c.name) {
      const cp = {
        type: c.name,
        id: item.id,
        componentType: item.componentType,
        config: item,
        data: [],
      };
      componentList.value.push(cp);
    }
  });
  let count = 0;
  return new Promise((resolve) => {
    componentList.value.forEach(async (item) => {
      try {
        const res = await getComponentData({ id: item.id });
        item.data = res[opts.componentEnum[item.componentType].listName] || [];
        item.navList = res.navList || [];
      } catch (err) {}
      count++;
      if (count === componentList.value.length) {
        resolve(componentList.value);
      }
    });
  });
};
const isDomain = computed(() => {
  return domain.value == "drjy.ykexam.com";
  // return domain.value == "hnkdtdxlkspx.ykumda.com";
});
const isDomain1 = computed(() => {
  return domain.value == "edu.shanxiyixue.com";
  // return domain.value == "192.168.10.34";
});
const isDomain2 = computed(() => {
  return domain.value == "hnkdtdxlkspx.ykumda.com" || domain.value == "192.168.10.8";
  // return domain.value == "192.168.10.34";
});
// const enabledBranchShop = computed(() => {
//   const branchShop = shopData.value?.enabledBranchShop;
//   return branchShop;
// });
const userInfo = () => {
  let sessData = getSession("userInfo");
  if (sessData && typeof sessData == "string") {
    sessData = JSON.parse(sessData);
  }
  return sessData;
};
onMounted(() => {
  domain.value = window.location.hostname;
  sessData1.value = userInfo();
  if (isDomain2.value && !sessData1.value) {
    router.replace({ path: "/login" });
  }
  if (
    sessData1.value &&
    sessData1.value.isReadContract == false &&
    shopData.value.enabledBranchShop == true &&
    shopData.value.contractExist == true
  ) {
    router.replace({ path: "/contract" });
  }
  getTemplate();
  const channel = new BroadcastChannel("unique_page_channel");
  // 监听来自其他页面的消息
  channel.onmessage = function (event) {
    if (event.data === "close") {
      // 接收到关闭消息时关闭页面
      window.close();
    }
  };

  // 发送消息告知其他页面已经打开
  channel.postMessage("open");
  // // debugger;
  // if (localStorage.getItem('isPageOpen')) {
  //   // 关闭之前已经打开的页面
  //   window.close();
  // } else {
  //   // 设置标记表示页面已经打开
  //   localStorage.setItem('isPageOpen', true);
  // }
  // if (process.client) {
  //     const route = window.location.host;
  //     if (route) {
  //       // redirect(route)
  //       window.close()
  //     }
  // }
});
</script>
<style lang="scss" scoped></style>
