<template>
  <NuxtLayout>
    <div class="app_container">
      <div class="paper_content">
        <div class="paper_header_content mgt20 paperStep">
          <div class="paper_row">
            <span class="left_title mgr10">考试类型：</span>
            <div class="search_type_list bg_box">
              <span v-for="item in opts.navTypeList" :key="item.navCode" class="search_type_item"
                :class="{ active: activeItem === item.navCode }" @click="changeTab(item)">{{ item.navName }}</span>
            </div>
          </div>
          <div v-if="ctrl.showCate" class="bg_box search_tag_list">
            <!-- <div>{{ opts.categoryList }}</div> -->
            <CategoryList v-for="(category, index) in opts.categoryList" :key="category.active" :index="index"
              :menu="category" :resourceNavType="2" @change="handleChange" />
          </div>
        </div>
        <div v-loading="ctrl.tableLoading" class="mgb20">
          <OnlineExamData :list="opts.tableList" :tabs="activeItem" />
        </div>
        <div v-show="page.totalPage > 1" ref="content" class="page_list">
          <el-pagination v-model:currentPage="page.pageCurrent" background layout="prev, pager, next,jumper"
            :total="page.totalCount" :page-size="page.pageSize" @current-change="handleChangeCurrent" />
        </div>
      </div>
    </div>
  </NuxtLayout>
</template>
<script setup>
import OnlineExamData from "./OnlineExamData.vue";
import CategoryList from "./CategoryList.vue";
import cookie from "@/utils/cookies";
import { computed, nextTick, onMounted, watch } from "vue";
import { useUserStore } from "@/stores/user";
import { getStore, setStore } from "@/utils/storage";
import {
  onlineExamPage,
  authPaperConfigPage,
  paperConfigPage,
  errorQuestionMoveLastErrorQuestionRecord,
  practiceAuthPage,
} from "@/api/paper";
import { getNavTypeList, getAuthCourseList, getCourseList } from "@/api/course";
import { categoryListS, enumList } from "@/api/system";

const route = useRoute();
const router = useRouter();
const cookieData = cookie.getShop();
const href = ref(null)
useHead({
  title: computed(() => `在线考试-${cookieData.shopName}`),
  meta: [
    {
      hid: "keywords",
      name: "keywords",
      content: "在线考试",
    },
    {
      hid: "description",
      name: "description",
      content: "在线考试",
    },
  ],
});
const store = useUserStore();
const { getToken, getEnumList, userView } = store;
const header = ref(null);
const content = ref(null);
const activeItem = ref(1);
const map = reactive({
  categoryId: "",
  chargeType: "", // 0:免费、1:收费
  examOrderByType: 1,
  isCourseQuestion: 2,
  publishStatus:1,
});
const token = computed(() => {
  return getToken();
});
const userInfo = computed(() => {
  return userView;
});
const opts = reactive({
  errorNum: 0,
  PracticeTypeEnum: [
    { code: 7, desc: "每日一练" },
    { code: 8, desc: "章节练习" },
    { code: 9, desc: "闯关练习" },
    { code: 10, desc: "模拟考试" },
    { code: 11, desc: "专项练习" },
  ],
  navTypeList: [],
  tableList: [],
  categoryList: [],
  currentTwoCategory: {},
  infoTimer: null,
  style: {
    height: "525px",
  },
  lastCategory: [],
  ChargeTypeEnum: [],
  ExamOrderByTypeEnum: [],
  categoryChild: {},
  isFree: false,
});
const ctrl = reactive({
  tableLoading: false,
  showCate: true,
});
const page = reactive({
  pageCurrent: 1,
  pageSize: 20,
  totalCount: 0,
  totalPage: 1,
});
const handleOrderByPrice = (bool) => {
  map.examOrderByType = 4;
  map.orderByPrice = bool;
  if (activeItem.value === 4 || route.query.practiceType === 4) {
    getTableList();
  } else {
    getOnlineExamList();
  }
};
const domain = ref("")
const isDomain = computed(() => {
  return domain.value != "drjy.ykexam.com";
  // return domain.value != "hnkdtdxlkspx.ykumda.com";
});

const handleChangeCurrent = (current) => {
  page.pageCurrent = current;
  if (activeItem.value === 4 || route.query.practiceType === 4) {
    getTableList();
  } else {
    getOnlineExamList();
  }
  document.querySelector("#app").scrollTop = 0;
};

const getOnlineExamList = () => {
  ctrl.tableLoading = true;
  onlineExamPage({
    pageCurrent: page.pageCurrent,
    pageSize: page.pageSize,
    courseType: route.query.practiceType ? route.query.practiceType : activeItem.value,
    ...map,
  })
    .then((res) => {
      // console.log(res, 'res');
      ctrl.tableLoading = false;
      opts.tableList = res.list || [];
      page.pageCurrent = res.pageCurrent;
      page.totalCount = res.totalCount;
      page.totalPage = res.totalPage;
      if (activeItem.value === 2) {
        opts.tableList.forEach((el) => {
          el.show = el.show === true || el.show === false ? el.show : true;
        });
      }
      if (opts.infoTimer) {
        clearInterval(opts.infoTimer);
        opts.infoTimer = null;
      }
    })
    .catch(() => {
      ctrl.tableLoading = false;
    });
};

const getTableList = () => {
  console.log('111');
  ctrl.tableLoading = true;
  if (token.value) {
    authPaperConfigPage({
      pageCurrent: page.pageCurrent,
      pageSize: page.pageSize,
      publishStatus:1,
      ...map,
    })
      .then((res) => {
        ctrl.tableLoading = false;
        opts.tableList = res.list || [];
        page.pageCurrent = res.pageCurrent;
        page.totalCount = res.totalCount;
        page.totalPage = res.totalPage;
        if (opts.infoTimer) {
          clearInterval(opts.infoTimer);
          opts.infoTimer = null;
        }
      })
      .catch(() => {
        ctrl.tableLoading = false;
        // opts.errorNum++;
        // if (!opts.infoTimer) {
        //  opts.infoTimer = setInterval(getTableList, 3000);
        // }
        // if (opts.errorNum > 5) {
        //  if (opts.infoTimer) {
        //    clearInterval(opts.infoTimer);
        //    opts.infoTimer = null;
        //  }
        // }
      });
  } else {
    paperConfigPage({
      pageCurrent: page.pageCurrent,
      pageSize: page.pageSize,
      publishStatus:1,
      ...map,
    })
      .then((res) => {
        ctrl.tableLoading = false;
        opts.tableList = res.list || [];
        page.pageCurrent = res.pageCurrent;
        page.totalCount = res.totalCount;
        page.totalPage = res.totalPage;
        if (opts.infoTimer) {
          clearInterval(opts.infoTimer);
          opts.infoTimer = null;
        }
      })
      .catch(() => {
        ctrl.tableLoading = false;
      });
  }
};
const getData = (query) => {
  if (query.practiceType) {
    activeItem.value = Number(query.practiceType);
  }
  if (query.examOrderByType) {
    map.examOrderByType = Number(query.examOrderByType);
  }

  if (query.categoryId) {
    map.categoryId = query.categoryId;
  } else {
    map.categoryId = "";
  }

  if (query.chargeType) {
    map.chargeType = Number(query.chargeType);
  }

  page.pageCurrent = 1;
  if (activeItem.value === 4 || route.query.practiceType === 4) {
    getTableList();
  } else {
    getOnlineExamList();
  }
};
const handleChangeCharge = (val) => {
  // 收费状态
  map.chargeType = val;
  const query = route.query || {};
  router.replace({
    query: Object.assign({ ...query }, { chargeType: val }, () => { }),
  });
};
const handleChangeOrderBy = (val) => {
  // 排序
  map.examOrderByType = val;
  if (val == 4) {
    map.orderByPrice = false;
    map.orderByType = 5;
  } else {
    if (map.orderByPrice === true || map.orderByPrice === false) {
      delete map.orderByPrice;
    }
  }
  const query = route.query || {};
  router.replace({
    query: Object.assign({ ...query }, { examOrderByType: val }, () => { }),
  });
};

const getCategoryObj = (list, id) => {
  // 获取分类对象
  let obj = null;
  list.forEach((el) => {
    if (el.id === id) {
      obj = el;
    }
    if (el.childrenList && el.childrenList.length > 0 && !obj) {
      obj = getCategoryObj(el.childrenList, id);
    }
  });
  return obj || {};
};

if (route.query.practiceType) {
  activeItem.value = Number(route.query.practiceType);
}
if (route.query.examOrderByType) {
  map.examOrderByType = Number(route.query.examOrderByType);
}
if (route.query.categoryId) {
  map.categoryId = route.query.categoryId;
}
if (route.query.chargeType) {
  map.chargeType = Number(route.query.chargeType);
}

let selectCategory = [];
// 分类查询更换
const handleChange = (index, row) => {
  if (selectCategory[index] !== row.id) {
    selectCategory[index] = row.id;
    selectCategory.splice(index + 1, selectCategory.length - index + 1);
    if (row.childrenList && row.childrenList.length) {
      ctrl.showCate = false;
      if (opts.categoryList.length > index + 1) {
        opts.categoryList.length = index + 1;
      }
      opts.categoryList.push({
        active: row.id,
        all: row.id,
        list: [{ id: row.id, categoryName: "全部" }].concat(row.childrenList),
      });
      ctrl.showCate = true;
    } else {
      opts.categoryList.length = index + 1;
      selectCategory.length = index + 1;
    }
  }
  const query = Object.assign(
    { ...(route.query || {}) },
    { categoryId: selectCategory[selectCategory.length - 1] || "" }
  );
  if (!query.categoryId) {
    delete query.categoryId;
  }
  router.push({
    query,
  });
};
const changeTab = (data) => {
  if (activeItem.value !== data.navCode) {
    data.navCode = data.navCode != 12 ? data.navCode : null;
    activeItem.value = data.navCode;
    page.pageCurrent = 1;
    const query = Object.assign(
      { ...(route.query || {}) },
      { practiceType: activeItem.value }
    );
    if (!query.categoryId) {
      delete query.categoryId;
    }
    router.replace({
      query,
    });
  }
};
const { data, pending, error } = await useAsyncData(
  "mockExamPage",
  () => {
    return new Promise(async (resolve) => {
      map.categoryId = route.query.categoryId || "";
      if (route.query.practiceType) {
        activeItem.value = Number(route.query.practiceType);
      }
      // 试卷列表的请求
      const params = {
        pageCurrent: page.pageCurrent,
        pageSize: page.pageSize,
        ...map,
      };
      let requestFn;
      if (activeItem.value === 4 || route.query.practiceType === 4) {
        requestFn = authPaperConfigPage;
      } else {
        requestFn = onlineExamPage;
        params.practiceType = activeItem.value;
      }
      if (!token.value) {
        if (activeItem.value === 4 || route.query.practiceType === 4) {
          requestFn = paperConfigPage;
        } else {
          requestFn = onlineExamPage;
          params.practiceType = activeItem.value;
        }
      }
      Promise.allSettled([
        requestFn(params),
        categoryListS({}),
        getEnumList("ChargeTypeEnum", "arr"),
      ]).then(([paper, categoryList, ChargeTypeEnum]) => {
        const data = {};
        if (paper.status === "fulfilled") {
          data.paper = paper.value;
        } else {
          data.paper = {
            list: [],
            pageCurrent: 1,
            pageSize: 10,
            totalPage: 1,
            totalCount: 0,
          };
        }
        if (categoryList.status === "fulfilled") {
          data.categoryList = categoryList.value;

        } else {
          data.categoryList = [];
        }
        if (ChargeTypeEnum.status === "fulfilled") {
          data.ChargeTypeEnum = ChargeTypeEnum.value;
        } else {
          data.ChargeTypeEnum = [];
        }
        data.ExamOrderByTypeEnum = [
          {
            desc: "综合",
            code: 1,
          },
          {
            desc: "最新",
            code: 2,
          },
          {
            desc: "按人气",
            code: 3,
          },
          {
            desc: "价格",
            code: 4,
          },
        ];
        resolve(data);
        // console.log(data, '11');
      });
    });
  },
  { initialCache: false }
);
setTimeout(() => {
  if (!pending.value && data) {
    const d = data.value;
    opts.ChargeTypeEnum = d.ChargeTypeEnum;
    opts.ExamOrderByTypeEnum = d.ExamOrderByTypeEnum;
    if (d && d.paper) {
      ctrl.tableLoading = false;
      opts.tableList = d.paper.list || [];
      page.pageCurrent = d.paper.pageCurrent;
      page.totalCount = d.paper.totalCount;
      page.totalPage = d.paper.totalPage;
      if (activeItem.value === 2) {
        opts.tableList.forEach((el) => {
          el.show = true;
        });
      }
    }
    setTimeout(() => {
      if (route.query.practiceType) {
        activeItem.value = Number(route.query.practiceType);
      }
    }, 800);
    if (d.categoryList && d.categoryList.length) {
      if (route.query.categoryId) {
        const classifyIdObj = {};
        const key = [];
        const init = (list, prefixId) => {
          list.forEach((e) => {
            if (e.id) {
              const id = prefixId + "_" + e.id;
              key.push(id);
              classifyIdObj[e.id] = e;
              if (e.childrenList && e.childrenList.length) {
                return init(e.childrenList, id);
              }
            }
          });
        };
        init(d.categoryList, "");

        const lists = key.filter((e) => e.indexOf(route.query.categoryId) > -1);
        if (lists && lists.length) {
          lists.sort((a, b) => {
            return a.length - b.length;
          });
          const ids = lists[0].split("_");
          if (ids && ids.length) {
            opts.categoryList.push({
              active: ids[1],
              all: "",
              list: [{ id: "", categoryName: "全部" }].concat(d.categoryList),
            });
            ids.forEach((e, index) => {
              const item = classifyIdObj[e];
              if (item) {
                selectCategory.push(e);
                if (item.childrenList.length) {
                  // if (index === 1) {
                  //   opts.categoryList.push({
                  //     active: ids[index + 1] || e,
                  //     all: e,
                  //     list: [{ id: e, categoryName: "全部" }].concat(item.childrenList),
                  //   });
                  // }
                  opts.categoryList.push({
                    active: ids[index + 1] || e,
                    all: e,
                    list: [{ id: e, categoryName: "全部" }].concat(item.childrenList),
                  });
                }
              }
            });
          }
        }
      } else {
        selectCategory = [""];
        opts.categoryList.push({
          active: "",
          all: "",
          list: [{ id: "", categoryName: "全部" }].concat(d.categoryList),
        });
        // console.log(opts.categoryList, '11');
      }
    }
  }
}, 500)
const getNavList = () => {
  getNavTypeList({ resourceNavType: 2 }).then((res) => {
    if (res.length) {
      // opts.navTypeList = res.sort((a, b) => b.sort - a.sort);
      opts.navTypeList = res;
      opts.navTypeList.forEach((item) => {
        if (item.navCode == 12) {
          item.navCode = null;
        }
      });
      activeItem.value = opts.navTypeList[0].navCode;
      let flag = false;
      res.forEach((el) => {
        if (el.navCode === activeItem.value) {
          flag = true;
        }
      });
      if (!flag) {
        activeItem.value = opts.navTypeList[0].navCode;
      }
      // console.log(activeItem.value,route.query.practiceType);
      if (activeItem.value === 4 || route.query.practiceType == 4) {
        getTableList();
      } else {
        // console.log(111);
        getOnlineExamList();
      }
    }
  });
};
watch(
  () => route.fullPath,
  () => {
    if (
      Number(route.query.examOrderByType) !== map.examOrderByType ||
      Number(route.query.chargeType) !== map.chargeType ||
      Number(route.query.practiceType) !== activeItem.value ||
      route.query.categoryId !== map.categoryId
    ) {
      getData(route.query);
    }
  }
);
onMounted(() => {
  domain.value = window.location.hostname;
  getNavList();

});
onUnmounted(() => {
  if (opts.infoTimer) {
    clearInterval(opts.infoTimer);
    opts.infoTimer = null;
  }
});
</script>

<style scoped lang="scss">
.paper_content {
  width: 1200px;
  margin: auto;
  background: #f6f8fb;

  .paper_header_content {
    padding: 18px 20px 4px 20px;
    margin-bottom: 15px;
    background: #fff;
    border-radius: 5px;
  }
}

.app_container {
  overflow: auto;
}

.page_list {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrong_and_collect {
  position: absolute;
  right: 24px;
  top: 24px;
}

.search_type_list {
  // min-height: 50px;
  display: flex;
  //align-items: center;
  //flex-wrap: wrap;

  &+.search_type_list {
    //margin-top: 10px;
  }
}

.border_hr {
  border: 1px solid #becadb;
  height: 14px;
  margin-left: 30px;
  margin-right: 30px;
}

.mgr6 {
  margin-right: 6px;
}

.bg_box {
  width: 100%;
  background-color: #ffffff;
  //border-radius: 5px;
  //padding: 5px 10px;

  &:first-child {
    margin-top: 20px;
  }

  &+.bg_box {
    //margin-top: 10px;
  }
}

.fliter_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  margin-bottom: 15px;

  .left_wrapper {
    position: relative;
    background: #fff;
    border-radius: 40px;
    padding: 0 16px 0 5px;

    .search_item {
      margin: 12px 15px;
      display: inline-block;
      cursor: pointer;
    }

    .active {
      color: #2256f6;
      font-weight: 700;
    }

    .order_by_price1 {
      position: absolute;
      right: 19px;
      top: 16px;
      display: block;
      width: 0;
      height: 0;
      border-bottom: 5px solid #999;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
      cursor: pointer;
    }

    .active1 {
      border-bottom-color: #3d7fff;
    }

    .order_by_price2 {
      position: absolute;
      right: 19px;
      bottom: 17px;
      display: block;
      width: 0;
      height: 0;
      border-top: 5px solid #999;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
      cursor: pointer;
    }

    .active2 {
      border-top-color: #3d7fff;
    }
  }
}

.search_type_item {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #333333;
  cursor: pointer;
  margin-right: 10px;
  padding: 6px 10px;

  &.active {
    color: #2256f6;
    font-weight: bold;
    background: rgba(34, 86, 246, 0.05);
    border-radius: 5px;
  }
}

.paper_row {
  display: flex;
  margin-bottom: 14px;

  .left_title {
    color: #1a1a1a;
    height: 30px;
    line-height: 30px;
    min-width: 70px;
    background: #fff;
  }
}

:deep(.el-checkbox__input.is-checked + .el-checkbox__label) {
  color: #333;
}
</style>
