<template>
  <div class="handle_bar">
    <search-box v-if="componentInfo && componentInfo.contentSearch && !isDomain" />
    <!-- <client-only>
      <el-popover
        v-if="componentInfo && componentInfo.appDownload"
        placement="bottom"
        trigger="hover"
        :width="340"
      >
        <template #reference>
          <el-button
            link
            class="handle_bar_item"
          >
            APP下载
          </el-button>
        </template>
        <template #default>
          <div class="app_download_box">
            <div
              v-if="componentInfo?.androidUrl"
              class="code"
            >
              <qrcode
                :value="componentInfo.androidUrl.value"
                :size="140"
              />
              <div class="code-text">
                Android APP下载
              </div>
            </div>
            <div
              v-if="componentInfo?.iosUrl"
              class="code"
            >
              <qrcode
                :value="componentInfo.iosUrl.value"
                :size="140"
              />
              <div class="code-text">
                IOS APP下载
              </div>
            </div>
          </div>
        </template>
      </el-popover>
    </client-only> -->
    <!-- <span
      v-if="componentInfo && componentInfo.appDownload"
      class="handle_bar_item"
    >
      APP下载
    </span> -->
    <user-avatar />
  </div>
</template>

<script setup>
import { toRefs, defineProps, ref } from "vue";
const props = defineProps({
  componentInfo: {
    type: Object,
    default: function () {
      return {};
    },
  },
});
const componentInfo = toRefs(props.componentInfo);
const domain = ref("");
const isDomain = computed(() => {
  return domain.value == "drjy.ykexam.com";
  // return domain.value == "hnkdtdxlkspx.ykumda.com";
});
onMounted(() => {
  domain.value = window.location.hostname;
});
</script>

<style lang="scss" scoped>
.handle_bar_item {
  font-size: 16px;
  margin-right: 20px;
  white-space: nowrap;
}
.app_download_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .code {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &:first-child {
      margin-right: 30px;
    }
    .code-text {
      text-align: center;
      margin-top: 6px;
    }
  }
}
</style>
